import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client'
import { Suspense } from 'react'
import { ReactComponent as Logo } from './assets/rockon.svg';
import './styles.css'
import { App } from './App'

function Overlay() {
  const [scrambledText1, setScrambledText1] = useState('Google\'s St. John\'s Terminal Headquarters');
  const [scrambledText2, setScrambledText2] = useState('bryantplace.com');
  const [scrambledText3, setScrambledText3] = useState('<-- rock on !!');
  const [scrambledText4, setScrambledText4] = useState('[06/01/2023] - [11/01/2023]');

  useEffect(() => {
    scrambleText(scrambledText1, setScrambledText1);
    scrambleText(scrambledText2, setScrambledText2);
    scrambleText(scrambledText3, setScrambledText3);
    scrambleText(scrambledText4, setScrambledText4);
  }, []);

  const scrambleText = (text, setText) => {
    const length = text.length;
    let counter = 0;
    let shift = 0;
    let shiftCount = 0;
    let shiftDel = length * 1.4;

    const scramble = () => {
      counter++;
      setText(text.substring(0, shift) + makeString(length - shift));
      if (counter > 5) {
        shiftCount++;
      }
      if (shiftCount >= shiftDel) {
        shift++;
        shiftDel = shiftDel * 0.89;
        shiftCount = 0;
      }
      if (shift <= length) {
        setTimeout(scramble, 10);
      }
    }
    scramble();
  }

  const makeString = (length) => {
    var result = '';
    var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!"#$%&';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href="https://bryantplace.com/" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
        {scrambledText2}
        <br />
        {scrambledText3}
      </a>
      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>{scrambledText1}</div>
      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>{scrambledText4}</div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <>
    <Suspense fallback={null}>
      <App />
    </Suspense>
    <Overlay />
    <a href="https://bryantplace.com/" target="_blank" rel="noopener noreferrer">
      <Logo className="wiggle" style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} />
    </a>
  </>
)